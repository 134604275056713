import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { CSS_VARS } from '../utils/enums';
import Header from '../components/Header';
import { config } from '../utils/config';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth0 } from "@auth0/auth0-react";
import useLogout from '../utils/useLogout';
import { NavigateFunction } from 'react-router-dom';
import { loadUserDocs, purgeFiles, Document, ProcessingStatus } from '../utils/apiUtils';
import { useDisclosure, Flex, VStack, Heading, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Button,
    Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalFooter, ModalCloseButton, Text, CircularProgress, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useSubscriptionStatus from '../utils/useSubscriptionStatus';
import { FileCompleteIndicator, FileProcessingIndicator } from '../components/UploadedFilesTable';


const UploadedFilesPage = () => {
    const navigator = useNavigate();
    const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, isLoading, logout } = useAuth0();
    const {isSubscribed, isTrialUser, daysRemainingInTrial} = useSubscriptionStatus();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [userDocs, setUserDocs] = useState<Document[]>([]);
    const [showPurgeFilesModal, setShowPurgeFilesModal] = useState(false);
    const [showPurgingSpinnerModal, setShowPurgingSpinnerModal] = useState(false);

    useEffect(() => {
        getAccessTokenSilently().then((token) => {
            loadUserDocs(token).then((docs: Document[]) => {
                setUserDocs(docs);
            });
        });
    }, []);

    const deleteUserFiles = () => {
        setShowPurgingSpinnerModal(true);
        setShowPurgeFilesModal(false);
        getAccessTokenSilently().then((token) => {
            purgeFiles(token).then(success => {
                if (success) {
                    setUserDocs([]);
                    setShowPurgingSpinnerModal(false);
                    navigator('/getting-started');
                }
            });
        });
    }

    const closeModals = () => {
        setShowPurgeFilesModal(false);
        setShowPurgingSpinnerModal(false);
    }


    return (
        <>
        <Flex height={'100vh'} direction={'column'}>
            <Header
                user={user}
                isAuthenticated={isAuthenticated}
                isTrialUser={isTrialUser}
                daysRemainingInTrial={daysRemainingInTrial}
                onLogout={logout}
                onLogin={loginWithRedirect}
                navigator={navigator}
            />
            <VStack>
                <Heading>
                    Uploaded Files
                </Heading>
                <TableContainer>
                    <Table variant={'striped'} colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th>File Name</Th>
                                <Th>Uploaded On</Th>
                                <Th>Processing Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {userDocs.map((doc, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td>{doc.filename}</Td>
                                        <Td>{doc.uploaded_display}</Td>
                                        <Td>{doc.processing_status === ProcessingStatus.PROCESSED ? <FileCompleteIndicator/> : <FileProcessingIndicator/>}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Button colorScheme='red' onClick={() => {
                    setShowPurgeFilesModal(true);
                    onOpen();
                }}>
                    Delete All Files
                </Button>
            </VStack>
        </Flex>
        <Modal isCentered isOpen={isOpen} onClose={closeModals} closeOnOverlayClick={!showPurgingSpinnerModal}>
            <ModalOverlay/>
            <ModalContent>
                {showPurgeFilesModal && <>
                    <ModalHeader>Confirm File Deletion</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to delete all your files?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button variant={'outline'} onClick={() => closeModals()}>Cancel</Button>
                            <Button colorScheme={'red'} onClick={() => deleteUserFiles()}>Delete</Button>
                        </HStack>
                    </ModalFooter>
                </>}
                {showPurgingSpinnerModal && <>
                    <ModalHeader>Deleting Files</ModalHeader>
                    <ModalBody display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <CircularProgress isIndeterminate color='blue.500' />
                        <Text mt={4}>Deleting Files</Text>
                    </ModalBody>
                </>}
            </ModalContent>
        </Modal>
        </>
    );


    
}

export default UploadedFilesPage;