import { get } from "http";

// Define the types for your configuration settings
type Config = {
    BASE_URL: string;
    AUTH0_AUDIENCE: string;
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
    STRIPE_DASHBOARD_URL: string;
  };

  const inProdEnvironment = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'prod';
  const inQaEnvironment = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'qa';
  
  // Implement the functions with type annotations
  const getBaseUrl = (): string => {
    const dev = 'http://localhost:8001';
    const prod = 'https://api.specgpt.ai';
    const qa = 'https://api-qa.specgpt.ai';
    return inProdEnvironment ? prod : inQaEnvironment ? qa : dev;
  };
  
  const getAuth0Audience = (): string => {
    const dev = 'https://dev-api.specgpt.ai';
    const prod = 'https://api.specgpt.ai';
    return inProdEnvironment ? prod : dev;
  };
  
  const getAuth0Domain = (): string => {
    const dev = 'specgpt-dev.us.auth0.com';
    const prod = 'thelink.us.auth0.com';
    return inProdEnvironment ? prod : dev;
  };
  
  const getAuth0ClientId = (): string => {
    const dev = 'KAYNuwQALbq6Sb72WcuFJOjKoP0hExQA';
    const prod = 'sr2yGwVmtmsufUNJsj8ShUE8PeTNZqn1';
    return inProdEnvironment ? prod : dev;
  };

  const getStripeDashboardUrl = (): string => {
    const dev = 'https://billing.stripe.com/p/login/test_aEU2brggr8AVao89AA';
    const prod = 'https://billing.stripe.com/p/login/test_aEU2brggr8AVao89AA';
    return inProdEnvironment ? prod : dev;
  }
  
  // Export the configuration as a typed object
  export const config: Config = {
    BASE_URL: getBaseUrl(),
    AUTH0_AUDIENCE: getAuth0Audience(),
    AUTH0_DOMAIN: getAuth0Domain(),
    AUTH0_CLIENT_ID: getAuth0ClientId(),
    STRIPE_DASHBOARD_URL: getStripeDashboardUrl(),
  };

// export const BASE_URL = getBaseUrl();
// export const AUTH0_AUDIENCE = getAuth0Audience();
// export const AUTH0_DOMAIN = getAuth0Domain();
// export const AUTH0_CLIENT_ID = getAuth0ClientId();

