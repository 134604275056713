import React, { useState, useRef } from "react";

import { Flex, HStack, Icon, Textarea, forwardRef, TextareaProps } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";
import { FaCircleArrowUp } from "react-icons/fa6";


interface PromptBoxProps {
    userInput: string;
    onUserInputChange: (userInput: string) => void;
    submitMessage: () => void;
    padding?: number;
}

const AutoResizeTextarea = React.forwardRef<
    HTMLTextAreaElement,
    TextareaProps
>((props, ref) => {
    return <Textarea
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        ref={ref}
        minRows={1}
        as={ResizeTextarea}
        {...props}
    />;
  });

const ChatInput = ({submitMessage, userInput, onUserInputChange, padding}: PromptBoxProps) => {

    return (
        <HStack minW={{base: '80%', md: '60ch'}} maxW={"60ch"} pb={padding} pt={padding}>
            <AutoResizeTextarea 
                onChange={(e) => onUserInputChange(e.currentTarget.value)} 
                bg="white"
                value={userInput} 
                placeholder='Message' 
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        submitMessage();
                    }
                }}
            />
            <Flex height={"100%"} direction="column" justify="end">
                <Icon as={FaCircleArrowUp} boxSize={6} mb={2} _hover={{cursor: userInput ? 'pointer' : 'default'}} onClick={(e) => userInput ? submitMessage() : null}  color={userInput ? "blue.400" : "gray.300"} />
            </Flex>
        </HStack>
    )
}

export default ChatInput;