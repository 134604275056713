import React, { useEffect, useState } from "react";
import { VStack, Heading, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Document, Filename, ProcessingStatus, loadUserDocs } from "../utils/apiUtils";

export const FileProcessingIndicator = () => {
    return <HStack align={'center'}>
        <Spinner size='sm'/>
        <Text mb={0} pb={0}>Processing</Text>
    </HStack>
}

export const FileCompleteIndicator = () => {
    return <HStack align={'center'}>
        <CheckIcon color={'green.500'}/>
        <Text mb={0} pb={0}>Complete</Text>
    </HStack>
}

const UploadedFilesTable = ({uploadedFileNames}: {uploadedFileNames: Filename[]}) => {
    const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, isLoading, logout } = useAuth0();
    const [userDocs, setUserDocs] = useState<Document[]>([]);


    // useEffect(() => {
    //     console.log("uploadedFileNames changed:", uploadedFileNames)
    //     setUserDocs(uploadedFileNames.map((filename) => {
    //         return {
    //             filename: filename.filename,
    //             docid: filename.filename,
    //             link: '',
    //             uploaded: '',
    //             processing_status: ProcessingStatus.PROCESSING,
    //         }
    //     }));
    // }, [uploadedFileNames]);


    useEffect(() => {
        const fetchDocs = () => {
            getAccessTokenSilently().then((token) => {
                loadUserDocs(token).then((docs: Document[]) => {
                    setUserDocs(docs.sort((a, b) => b.uploaded - a.uploaded));
                });
            });
        };
        fetchDocs();

        const intervalId = setInterval(fetchDocs, 5000); // Then every 5 seconds
    
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    

    return (
        <>
        {userDocs.length > 0 && <VStack>
            <Heading>
                Uploaded Files
            </Heading>
            <TableContainer>
                <Table variant={'striped'} colorScheme='gray'>
                    <Thead>
                        <Tr>
                            <Th>File Name</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userDocs.map((doc, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{doc.filename}</Td>
                                    <Td>{doc.processing_status === ProcessingStatus.PROCESSED ? <FileCompleteIndicator/> : <FileProcessingIndicator/>}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>}
        </>
    )
}

export default UploadedFilesTable;
