import logo from './logo.svg';
import './App.css';
import Chat from './pages/ChatPage';
import LoginPage from './pages/LoginPage';
import useToken from './utils/useToken';
import ViewPDFPage from './pages/ViewPDFPage';
import { Routes, Route, BrowserRouter, useNavigate, Navigate } from 'react-router-dom';
import GettingStartedPage from './pages/GettingStartedPage';
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import UploadedFilesPage from './pages/UploadedFilesPage';
import { useEffect } from 'react';
import useSubscriptionStatus from './utils/useSubscriptionStatus';
import { hasActiveSubscription } from './utils/apiUtils';
import { LoadingPage } from './pages/LoadingPage';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';




function App() {
  const {isLoading, isAuthenticated, user, getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const {isSubscribed, setIsSubscribed, isTrialUser, setIsTrialUser, daysRemainingInTrial, setDaysRemainingInTrial, isLoadingSubscriptionState, setIsLoadingSubscriptionState, isEmailVerified} = useSubscriptionStatus();

  const [searchParams, setSearchParams] = useSearchParams();

  const returningFromSuccessfulCheckout = searchParams.get('subscriptionSuccess') === '1';

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // in this case the user is not authenticated, so we can assume they are not subscribed
      setIsSubscribed(false);
      setIsLoadingSubscriptionState(false);
    }
  }, [user, isAuthenticated, isLoading]);


  useEffect(() => {
    if (isLoadingSubscriptionState) {
      // If you have logic that should run when isLoading is true, place it here.
      return; // Prevents the rest of the hook from running when isLoading.
    }

    // if (isAuthenticated && user) {
    //   window.heap.identify(user.email);
    // }
    // The dependencies array ensures this effect runs only when isLoading, isAuthenticated, or user.email changes.
  }, [isLoadingSubscriptionState, isAuthenticated, user]);

  if (isLoadingSubscriptionState) {
    return <LoadingPage />
  }

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/getting-started" state={{subscriptionSuccess: returningFromSuccessfulCheckout}} /> : <LoginPage />} />
      <Route exact path="/chat" element={<AuthenticationGuard component={Chat} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />                
      <Route exact path="/source" element={<AuthenticationGuard component={ViewPDFPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
      <Route exact path="/getting-started" element={<AuthenticationGuard component={GettingStartedPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
      <Route exact path="/uploaded-files" element={<AuthenticationGuard component={UploadedFilesPage} isSubscribed={isSubscribed} isEmailVerified={isEmailVerified} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />} />
      {/* <Route exact path="/admin-dashboard" element={<AdminDashboardPage />} /> */}
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
  )
}


export default App;
