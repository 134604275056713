import React from "react";
import { User } from "@auth0/auth0-react"
import { HamburgerIcon, AddIcon, ChatIcon, ViewIcon } from "@chakra-ui/icons";
import { NavigateFunction } from "react-router-dom";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    Text,
    MenuList, MenuItem, MenuGroup, List, ListItem,
    Button, 
    Box,
    ListIcon,
    VStack,
    HStack
  } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { HamburgerMenuProps } from './HamburgerMenu';
import { createStripePortalSession } from '../utils/apiUtils';
import { useAuth0 } from '@auth0/auth0-react';

export const HamburgerDrawer = ({user, onLogout, navigator, display, isTrialUser, daysRemainingInTrial}: HamburgerMenuProps) => {
    const {getAccessTokenSilently} = useAuth0();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef(null);

    const createPortalSession = async () => {
        const token = await getAccessTokenSilently();
        return createStripePortalSession(token);
    }

    return (
        <>
          <IconButton display={display} icon={<HamburgerIcon color={"blue.900"} boxSize={6}/>} ref={btnRef} aria-label='Open Hamburger Menu' bg={'transparent'} onClick={onOpen}/>
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>{`${user?.name} (${user?.email})`}</DrawerHeader>
    
              <DrawerBody>
                <List spacing={4}>
                  <ListItem onClick={() => navigator('/chat')} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                      <ListIcon as={ChatIcon} />
                      Chat
                  </ListItem>
                  <ListItem onClick={() => navigator('/getting-started')} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                      <ListIcon as={AddIcon} />
                      Add Documents
                  </ListItem>
                  <ListItem onClick={() => navigator('/uploaded-files')} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                      <ListIcon as={ViewIcon} />
                      View Documents
                  </ListItem>
                </List>
              </DrawerBody>
    
              <DrawerFooter>
                <VStack>
                  {isTrialUser && (
                    <Text>{`${daysRemainingInTrial} day${daysRemainingInTrial > 1 ? 's' : ''} remaining in trial.`}</Text>
                  )}
                  <HStack>
                    <Button colorScheme='yellow' mr={3} onClick={onLogout}>
                      Logout
                    </Button>
                    <Button variant='outline' onClick={() => createPortalSession().then((url: string | null) => {
                            if (url) {
                                window.open(url, '_blank');
                            }
                        })}>Manage Subscription</Button>
                  </HStack>
                </VStack>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      )
};

export default HamburgerDrawer;

