import React, { useState, useEffect, useRef } from 'react';

import { NavigateFunction } from 'react-router-dom';
import { CSS_VARS } from '../utils/enums';
import { ChatMessage } from '../utils/apiUtils';
import { Box, Heading, List, ListItem, ListIcon, HStack, Button } from '@chakra-ui/react';
import { ChatIcon, AddIcon } from '@chakra-ui/icons';


export interface ChatHistoryProps {
    chatHistory: ChatMessage[];
    onClickChatLink: (chatSessionId: string) => void;
    display: string;
    onNewChatClick: () => void;
}


const ChatHistoryDesktop = ({chatHistory, onClickChatLink, display, onNewChatClick}: ChatHistoryProps) => {
    return (
        <Box display={display} minWidth={'300px'} width={'15vw'} p={2} color={"white"} bg={"blue.900"} overflowY={'auto'}>
            <Heading mb={4}>SpecGPT</Heading>
            <HStack align={'center'} justify={'space-between'}><Heading pb={0} mb={0} size={"md"}>Chat History</Heading><Button colorScheme='yellow' size='sm' onClick={onNewChatClick}><AddIcon/>New Chat</Button></HStack>
            <List spacing={4}>
                {chatHistory.map((chat, index) => {
                    return (
                        <ListItem
                            onClick={() => onClickChatLink(chat.session_id)} 
                            color="white"
                            _hover={{bg: 'lightBlue.50', color: 'gray.900', cursor: 'pointer'}}
                            key={index}>
                                <ListIcon as={ChatIcon} />{' '} {chat.question}
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default ChatHistoryDesktop;

