 import React, {useEffect, useState, useMemo, forwardRef} from 'react';
 import Header from '../components/Header';
 import ChunkedUploady,
 {
    useItemFinishListener,
    UPLOADER_EVENTS,
    Batch,
    BatchItem,
 } from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { config } from '../utils/config';
import { FaTriangleExclamation, FaCheck } from 'react-icons/fa6';
import { useNavigate, Link, NavigateFunction } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import useSubscriptionStatus from '../utils/useSubscriptionStatus';
import { countUserDocs, UploadItemResponse, Filename } from '../utils/apiUtils';
import { CircularProgress, Modal, ModalContent, ModalHeader, ModalOverlay, Text, Button,
     ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Flex, Stack, VStack, Heading, UnorderedList, ListItem, Box, HStack, Icon } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import UploadedFilesTable from '../components/UploadedFilesTable';
import { Document } from '../utils/apiUtils';


 const GettingStartedPage = () => {
    const navigator = useNavigate();
    const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, isLoading, logout } = useAuth0();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showUploadingModal, setShowUploadingModal] = useState(false);
    const [showUploadCompleteModal, setShowUploadCompleteModal] = useState(false);
    const {isSubscribed, isTrialUser, daysRemainingInTrial} = useSubscriptionStatus();

    const [uploadedFiles, setUploadedFiles] = useState<Filename[]>([]);
    const [docCount, setDocCount] = useState(0);
    const [token, setToken] = useState('');
    const [uploadingFilesProgressText, setUploadingFilesProgressText] = useState('Uploading Files.');
    const [totalFilesToUpload, setTotalFilesToUpload] = useState(0);
    const [filesUploaded, setFilesUploaded] = useState(0);
    const [showUploadMaxError, setShowUploadMaxError] = useState(false);
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);    

    // Define a new component that will use the useItemFinishListener hook
    // This component needs to be rendered inside the <ChunkedUploady> component
    const UploadListenerComponent = () => {
        useItemFinishListener((item) => {
            console.log('item: ', item)
            uploadCompleteCallback(item.uploadResponse.results[0] as {data: UploadItemResponse});
            console.log(`item ${item.id} finished uploading, response was: `, item.uploadResponse, item.uploadStatus);  
        });

        // This component does not render anything itself
        return null;
    };

    const closeModals = () => {
        setTotalFilesToUpload(0);
        setFilesUploaded(0);
        setUploadErrors([]);
        setShowUploadMaxError(false);
        setShowUploadingModal(false);
        setShowUploadCompleteModal(false);
        onClose()
    }


    const uploadCompleteCallback = (item: {data: UploadItemResponse}) => {        
        updateDocCount();
        console.log('upload call back ', item);
        if (item.data?.reachedUploadLimit) {
            setShowUploadMaxError(true);
            return;
        } else if (!item.data.success) {
            if (item.data.error && item.data.error !== '') {
                setUploadErrors([...uploadErrors, item.data.error]);
            }
        }
        updateUploadText(filesUploaded, totalFilesToUpload);
        if(item.data.filenames) {
            let newUploadedFiles = [...uploadedFiles, ...item.data.filenames];
            // sort newUploadedFiles
            newUploadedFiles.sort((a, b) => {
                if (a.filename < b.filename) {
                    return -1;
                }
                if (a.filename > b.filename) {
                    return 1;
                }
                return 0;
            });
            console.log('newUploadedFiles: ', newUploadedFiles);        
            setUploadedFiles([...newUploadedFiles]);
        }
    }

    const updateDocCount = () => {
        getAccessTokenSilently().then((token) => {
            setToken(token);
            countUserDocs(token).then((count) => {
                setDocCount(count || 0);
            });
        });
    }

    useEffect(() => {
        updateDocCount()
    }, []);

    const updateUploadText = (filesUploadedSoFar: number, totalFilesToUpload: number) => {
        setFilesUploaded(filesUploadedSoFar + 1); 
        setTotalFilesToUpload(totalFilesToUpload);
        const percent = (filesUploadedSoFar / totalFilesToUpload) * 100;
        const text = `Uploading Files. ${filesUploadedSoFar} of ${totalFilesToUpload} uploaded. ${percent.toFixed(0)}% complete.`;
        setUploadingFilesProgressText(text);
    }

    const listeners = useMemo(() => ({
        [UPLOADER_EVENTS.BATCH_START]: (batch: Batch) => {
            updateUploadText(filesUploaded, batch.items.length);
            onOpen();
            setShowUploadingModal(true);
            //console.log(`Batch Start - ${batch.id} - item count = ${batch.items.length}`);
        },
        [UPLOADER_EVENTS.BATCH_FINISH]: (batch: Batch) => {
            onOpen();
            setShowUploadingModal(false);
            setShowUploadCompleteModal(true);
            //console.log(`Batch Finish - ${batch.id} - item count = ${batch.items.length}`);
        },
        [UPLOADER_EVENTS.ITEM_START]: (item: BatchItem) => {
            //console.log(`Item Start - ${item.id} : ${item.file.name}`);
        },
        [UPLOADER_EVENTS.ITEM_FINISH]: (item: BatchItem) => {
            //console.log(`Item Finish - ${item.id} : ${item.file.name}`);
        },

    }), []);

    return (
        <>
        <Flex height={'100vh'} direction={'column'}>
            <Header
                user={user}
                isAuthenticated={isAuthenticated}
                isTrialUser={isTrialUser}
                daysRemainingInTrial={daysRemainingInTrial}
                onLogout={logout}
                onLogin={loginWithRedirect}
                navigator={navigator}
            />
            <Stack direction={{base: 'column', md: 'row-reverse'}} justify={{base: 'start', md: 'center'}} p={4}>
                <VStack w={{base: '100%', md: '50%'}} maxW={{md: '60ch'}}>
                    <Heading>Upload Your Specs</Heading>
                    <Box display={{base: 'none', md: 'block'}} maxW={{base: '95%', md: '60ch'}} className="col-12 mb-3">
                        <ChunkedUploady
                            listeners={listeners}
                            chunkSize={process.env.NODE_ENV === 'development' ? 5 * 1024 * 1024 : 10 * 1024 * 1024} // 5MB for dev, 10MB for prod
                            destination={{url: `${config.BASE_URL}/api/upload`,
                                    headers: {
                                    "Authorization": "Bearer " + token,
                                },
                            }}>
                            <UploadDropZone
                                onDragOverClassName="drag-over"
                            >
                                <Flex direction={'column'} justify={'center'} align={'center'} height={'300px'} width={'100%'} p={4} borderRadius={6} bgColor='gray.100'>
                                    <Text>
                                        Drag & Drop File(s) or Folders here
                                    </Text>
                                    <Text>
                                        Or click below
                                    </Text>
                                </Flex>
                            </UploadDropZone>
                            <UploadListenerComponent />
                        </ChunkedUploady>
                    </Box>
                    <HStack>
                        <Box>
                            <ChunkedUploady
                                listeners={listeners}
                                chunkSize={process.env.NODE_ENV === 'development' ? 5 * 1024 * 1024 : 10 * 1024 * 1024} // 5MB for dev, 10MB for prod
                                accept='application/pdf'
                                destination={{url: `${config.BASE_URL}/api/upload`,
                                    headers: {
                                        "Authorization": "Bearer " + token,
                                    },
                                }}
                            >   
                                <Button width={{base: '100%'}} height={'75px'} maxW={{md: '60ch'}} colorScheme='blue' >
                                    <UploadButton
                                        text="Click to select PDFs"
                                    />
                                </Button>
                                <UploadListenerComponent />
                            </ChunkedUploady>
                        </Box>
                        <Box>
                            <ChunkedUploady
                                listeners={listeners}
                                chunkSize={process.env.NODE_ENV === 'development' ? 5 * 1024 * 1024 : 10 * 1024 * 1024} // 5MB for dev, 10MB for prod
                                destination={{url: `${config.BASE_URL}/api/upload`,
                                    headers: {
                                        "Authorization": "Bearer " + token,
                                    }
                                }}
                            >
                                <Button width={{base: '100%'}} height={'75px'} maxW={{md: '60ch'}} colorScheme='blue' >
                                    <UploadButton
                                        text="Click to select Folders"
                                    />
                                </Button>
                                <UploadListenerComponent />
                            </ChunkedUploady>
                        </Box>
                    </HStack>
                    <Box>
                        <Button 
                            disabled={uploadedFiles.length === 0 && docCount === 0}
                            colorScheme='yellow'
                            onClick={() => {
                                navigator('/chat');
                            }}
                            rightIcon={<ArrowForwardIcon />}
                        >
                            Go To Chat Page
                        </Button>
                    </Box>
                </VStack>
                <VStack  w={{base: '100%', md: '50%'}} maxW={{md: '60ch'}} align={'start'}>
                    <Heading>Getting Started</Heading>
                    <Heading size={'md'}>Step 1</Heading>
                    <Text mb={1}>Click “Select files,” or “Select Folders” to upload specification documents.</Text>
                    <Text><b>Note:</b>&nbsp;At this time, SpecGPT has a file upload limit of 300 files.</Text>
                    <Heading size={'md'}>Step 2</Heading>
                    <Text>Wait for 2-5 minutes while the system processes your documents.</Text>
                    <Heading size={'md'}>Step 3</Heading>
                    <Text>Ask questions like you might in a real project</Text>

                    <Text fontSize={'sm'}>For help email <a href="mailto:support@thelink.ai" target="_blank">support@thelink.ai</a></Text>
                </VStack>
            </Stack>
            {uploadErrors.map((error, index) => {
                return (
                    <Text key={index}>
                        <Icon as={FaTriangleExclamation} size="lg" style={{color: "red.400",}} />
                        {' '} {error}
                    </Text>
                )
            })}
            {showUploadMaxError && (
                <Text style={{color: 'red'}}>
                    You have reached the maximum number of files allowed. Please delete some files and try again.
                </Text>
            )}
            <UploadedFilesTable uploadedFileNames={uploadedFiles}/>
        </Flex>
        <Modal closeOnOverlayClick={showUploadingModal ? false : true} isCentered isOpen={isOpen} onClose={closeModals}>
            <ModalOverlay/>
            <ModalContent>
                {showUploadingModal && <>
                    <ModalHeader>Uploading Files</ModalHeader>
                    <ModalBody>
                        <Flex justify={'center'} mb={2}>
                            <CircularProgress isIndeterminate color='blue.500' />
                        </Flex>
                        <Text>{uploadingFilesProgressText}</Text>
                    </ModalBody>
                </>}
                {showUploadCompleteModal && <>
                    <ModalHeader>Success</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Your files have been uploaded. SpecGPT is now processing your files. This may take a few minutes. When this is done, you can proceed to the chat page.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeModals}>OK</Button>
                    </ModalFooter>
                </>}
            </ModalContent>
        </Modal>
        </>
    )
 }

 export default GettingStartedPage;