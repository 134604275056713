import React from "react";
import { User } from "@auth0/auth0-react"
import { RepeatClockIcon, AddIcon, ChatIcon, ViewIcon } from "@chakra-ui/icons";
import { NavigateFunction } from "react-router-dom";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    Text,
    MenuList, MenuItem, MenuGroup, List, ListItem,
    Button, 
    Box,
    ListIcon
  } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { ChatHistoryProps } from './ChatHistoryDesktop';



export const ChatHistoryDrawer = ({chatHistory, onClickChatLink, display, onNewChatClick}: ChatHistoryProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef(null);
    console.log("ChatHistoryDrawer", chatHistory)

    return (
        <>
          <IconButton display={display} icon={<RepeatClockIcon color={"blue.900"} boxSize={6}/>} ref={btnRef} aria-label='Open Chat History' bg={'transparent'} onClick={onOpen}/>
          <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader pb={0}>{`Chat History`}</DrawerHeader>
              <DrawerBody>  
                <Button colorScheme='yellow' size='sm' onClick={onNewChatClick}><AddIcon/>New Chat</Button>
                <List mt={3} spacing={4}>
                    {chatHistory.map((chat) => {
                        return (
                            <ListItem key={chat.session_id} onClick={() => onClickChatLink(chat.session_id)} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                                <ListIcon as={ChatIcon} />
                                {chat.question + chat.session_id}
                            </ListItem>
                        )
                    })}
                </List>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )
};

export default ChatHistoryDrawer;
