// based on "Pallete 2" in Refactoring UI
export const lighter_blue_theme = {
    colors: {
      blue: {
        900: "#003E6B",
        800: "#0A558C",
        700: "#0F609B",
        600: "#186FAF",
        500: "#2680C2",
        400: "#4098D7",
        300: "#62B0E8",
        200: "#84C5F4",
        100: "#B6E0FE",
        50: "#DCEEFB",
      },
      yellow: {
        900: "#8D2B0B",
        800: "#B44D12",
        700: "#CB6E17",
        600: "#DE911D",
        500: "#F0B429",
        400: "#F7C948",
        300: "#FADB5F",
        200: "#FCE588",
        100: "#FFF3C4",
        50: "#FFFBEA",
      },
      // blue gray
      gray: {
        900: "#102A43",
        800: "#243B53",
        700: "#334E68",
        600: "#486581",
        500: "#627D98",
        400: "#829AB1",
        300: "#9FB3C8",
        200: "#BCCCDC",
        100: "#D9E2EC",
        50: "#F0F4F8",
      },
      lightBlue: {
        900: "#044E54",
        800: "#0A6C74",
        700: "#0E7C86",
        600: "#14919B",
        500: "#2CB1BC",
        400: "#38BEC9",
        300: "#54D1DB",
        200: "#87EAF2",
        100: "#BEF8FD",
        50: "#E0FCFF",
      },
      red: {
        900: "#610404",
        800: "#780A0A",
        700: "#911111",
        600: "#A61B1B",
        500: "#BA2525",
        400: "#D64545",
        300: "#E66A6A",
        200: "#F29B9B",
        100: "#FACDCD",
        50: "#FFEEEE",
      }
    },
    fonts: {
        heading: `'Roboto', sans-serif`,
        body: `'Source Sans Pro', sans-serif`,
    },
    fontWeights: {
        thin: 100,
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
        black: 900,
    }
}

// based on "Pallete 10" in Refactoring UI
export const darker_blue_theme = {
    colors: {
      blue: {
        900: "#19216C",
        800: "#2D3A8C",
        700: "#35469C",
        600: "#4055A8",
        500: "#4C63B6",
        400: "#647ACB",
        300: "#7B93DB",
        200: "#98AEEB",
        100: "#BED0F7",
        50: "#E0E8F9",
      },
      yellow: {
        900: "#8D2B0B",
        800: "#B44D12",
        700: "#CB6E17",
        600: "#DE911D",
        500: "#F0B429",
        400: "#F7C948",
        300: "#FADB5F",
        200: "#FCE588",
        100: "#FFF3C4",
        50: "#FFFBEA",
      },
      // cool gray
      gray: {
        900: "#1F2933",
        800: "#323F4B",
        700: "#3E4C59",
        600: "#52606D",
        500: "#616E7C",
        400: "#7B8794",
        300: "#9AA5B1",
        200: "#CBD2D9",
        100: "#E4E7EB",
        50: "#F5F7FA",
      },
      lightBlue: {
        900: "#035388",
        800: "#0B69A3",
        700: "#127FBF",
        600: "#1992D4",
        500: "#2BB0ED",
        400: "#40C3F7",
        300: "#5ED0FA",
        200: "#81DEFD",
        100: "#B3ECFF",
        50: "#E3F8FF",
      },
      red: {
        900: "#610316",
        800: "#8A041A",
        700: "#AB091E",
        600: "#CF1124",
        500: "#E12D39",
        400: "#EF4E4E",
        300: "#F86A6A",
        200: "#FF9B9B",
        100: "#FFBDBD",
        50: "#FFE3E3",
      }
    },
    fonts: {
        heading: `'Roboto', sans-serif`,
        body: `'Source Sans Pro', sans-serif`,
    },
    fontWeights: {
      thin: 100,
      light: 300,
      normal: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  }

