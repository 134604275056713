import { config } from "./config";
import { MESSAGE_ROLE_TYPE } from "./enums";

export const fetchPdf = async (fileId: string, authToken: string): Promise<string | null> => {
    try {
        const response = await fetch(`${config.BASE_URL}/api/signed-url?id=${fileId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + authToken,
            },
        });
        const data = await response.json();
        if (data.success) {
            console.log('signed url', data.url);
            return data.url
        }
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
    return null;
}

export const loadQuestionRating = async (questionId: string, token: string) => {
    const url = `${config.BASE_URL}/api/rating?question_id=${questionId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
        },
        });
        const data = await response.json();
        if (data.success) {
            return data.rating;
        }
    } catch (error) {
        console.log('error: ', error);
        return 0;
    }
    return 0;
}

export const rateQuestion = async (rating: number, questionid: string, token: string) => {
    const url = `${config.BASE_URL}/api/rating`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                question_id: questionid,
                rating: rating,
            })
        });
        const data = await response.json();
        if (data.success) {
            return data.rating;
        }
    } catch (error) {
        console.log('error: ', error);
        return 0;
    }
    return 0;
}

export interface Source {
    link: string;
    filename: string;
    docid: string;
    doc_uuid: string;
    chunk: number;
    text: string;
}

export interface ChatMessage {
    session_id: string,
    question: string,
}

export interface ChatMessageDetail {
    session_id: string;
    questionid: string;
    role: string;
    message: string;
    sources?: Source[];
}

export interface ChatHistoryData {
    success: boolean;
    chat_history: ChatMessage[];
}

export interface ChatHistoryDetail {
    success: boolean;
    chat_history: ChatMessageDetail[];
}

export interface Filename {
    filename: string;
}

export interface UploadItemResponse {
    success: boolean;
    message?: string;
    return_message?: string;
    error?: string;
    reachedUploadLimit?: boolean;
    filenames?: Filename[];
}

export const fetchChatHistory = async (token: string) => {
    const url = `${config.BASE_URL}/api/chat-history`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data: ChatHistoryData = await response.json();
        if (data.success) {
            return [...data.chat_history];
        }
    } catch (error) {
        console.log('Error: ', error);
        return [];
    }   
    return [];
}


export const fetchChatSessionHistory = async (chatSessionID: string, token: string) => {
    const url = `${config.BASE_URL}/api/chat-session-history?chat_session_id=${chatSessionID}`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data: ChatHistoryDetail = await response.json();
        if (data.success) {
            return data.chat_history
        }
    } catch (error) {
        console.log('Error: ', error);
        return [];
    }
    return [];
}

const ERROR_MESSAGE = "I’m unable to answer that question, can you please restate? Try to make it more specific or narrower if possible."

export const fetchPromptAnswer = async (prompt: string, k: number, chatSessionId: string, token: string): Promise<ChatMessageDetail> => {
    const url = `${config.BASE_URL}/api/doc-chat-stream`;            
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
        },
            body: JSON.stringify({
                'prompt': prompt,
                'k': k,
                'chat_session_id': chatSessionId,
            }),
        });
        if (!response.body) return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE} as ChatMessageDetail;
        if (response.status === 400) {
            // return 400 error
            const data = await response.json();
            const message = data.message;
            return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: message};
            }
        if (response.status === 500) {
            return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE} as ChatMessageDetail;
        }
        const data = await response.json();
        const answer = data.answer;
        const questionid = data.questionid;
        const sources = data.sources;
        console.log(sources);
        return {session_id: chatSessionId, questionid: questionid, role: MESSAGE_ROLE_TYPE.ASSISTANT, message: answer, sources: sources} as ChatMessageDetail;

    } catch (error) {
        console.log('Error: ', error);
        return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE} as ChatMessageDetail;
    }   
    return {session_id: chatSessionId, questionid: '', role: MESSAGE_ROLE_TYPE.ERROR, message: ERROR_MESSAGE} as ChatMessageDetail;
}

export const countUserDocs = async (token: string) => {
    const url = `${config.BASE_URL}/api/doc-count`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return data.count;
        }
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

export const purgeFiles = async (token: string) => {
    const url = `${config.BASE_URL}/api/purge-files`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return true;
        }
    } catch (error) {
        console.log('Error: ', error);
        return false;
    }
    return false;
}

export enum ProcessingStatus {
    IN_QUEUE = 'IN_QUEUE',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export interface Document {
    docid: string;
    filename: string;
    uploaded: number;
    uploaded_display: string;
    link: string;
    processing_status: ProcessingStatus;
}

export const loadUserDocs = async (token: string) => {
    const url =`${config.BASE_URL}/api/docs`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        if (data.success) {
            return data.docs as Document[];
        }            
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
    return [];
}

export const createStripeCheckoutSession = async (token: string): Promise<string | null> => {
    const url = `${config.BASE_URL}/create-checkout-session`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data.url;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

export const createStripePortalSession = async (token: string): Promise<string | null> => {
    const url = `${config.BASE_URL}/create-portal-session`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data.url;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}

export interface SubscriptionStatus {
    has_active_subscription: boolean;
    is_trial_user: boolean;
    days_remaining_in_trial: number;
    email_verified: boolean;
}


export const hasActiveSubscription = async (token: string): Promise<SubscriptionStatus | null> => {
    const url = `${config.BASE_URL}/get-subscription-status`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json()
        return data as SubscriptionStatus;
    } catch (error) {
        console.log('Error: ', error);
        return null;
    }
    return null;
}


export const resendVerificationEmail = async (token: string): Promise<boolean> => {
    const url = `${config.BASE_URL}/resend-verification-email`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('Error: ', error);
        return false;
    }
    return false;
}

