import React, { useState, useEffect } from 'react';
import { FaUser, FaCircleInfo, FaGear } from "react-icons/fa6";
import { Box, Flex, Text, Icon, Heading, Container, Skeleton } from '@chakra-ui/react';
import { MESSAGE_ROLE_TYPE } from '../utils/enums';
import { Source } from '../utils/apiUtils';
import MessageSources from './MessageSources';


interface MessageBubbleProps {
    message: string;
    messageType: MESSAGE_ROLE_TYPE;
    questionid?: string;
    sources?: Source[];
    rating?: number;
    isLoading?: boolean;
    onClick?: () => void;
} 

export const MessageBubble = ({message, messageType, questionid, sources, isLoading}: MessageBubbleProps) => {
    let messageIcon = FaGear;
    if (messageType == MESSAGE_ROLE_TYPE.ASSISTANT) {
        messageIcon = FaCircleInfo;
    }
    if (messageType == MESSAGE_ROLE_TYPE.USER) {
        messageIcon = FaUser;
    }
    
    let messageColor = "white"
    if (messageType == MESSAGE_ROLE_TYPE.USER) {
        messageColor = "blue.100"
    }

    let messageHeading = "System"
    if (messageType == MESSAGE_ROLE_TYPE.USER) {
        messageHeading = "You"
    }
    if (messageType == MESSAGE_ROLE_TYPE.ASSISTANT) {
        messageHeading = "SpecGPT"
    }
    return (
        <Container 
            bg={messageColor} 
            borderRadius={6}
            boxShadow={"base"}
            maxW={{base: "90%", md: '60ch'}}
            p={4}
            m={2}
            alignSelf={"center"}
            color={"gray.900"}>
            <Flex mb={4} >
                <Icon as={messageIcon} mr={2} />
                <Heading as="h6" size={"xs"}>{messageHeading}</Heading>
            </Flex> 
            <Text color={"gray.900"} whiteSpace={"pre-wrap"}>
                {isLoading ? <Skeleton height={6} width={"100%"} /> : message}
            </Text>
            {sources !== undefined && !isLoading && (
                    <MessageSources
                        sources={sources}
                    />
                )}
        </Container>   
    )
}
