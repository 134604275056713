import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';

import {hasActiveSubscription} from '../utils/apiUtils'



function useSubscriptionStatus() {
    const {user, getAccessTokenSilently} = useAuth0();

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isTrialUser, setIsTrialUser] = useState(false);
    const [daysRemainingInTrial, setDaysRemainingInTrial] = useState(0);
    const [isLoadingSubscriptionState, setIsLoadingSubscriptionState] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);


    useEffect(() => {
        if (user) {
            getAccessTokenSilently().then((token) => {
                hasActiveSubscription(token).then((subscriptionStatus) => {
                setIsSubscribed(subscriptionStatus?.has_active_subscription ?? false);
                setIsTrialUser(subscriptionStatus?.is_trial_user ?? false);
                setDaysRemainingInTrial(subscriptionStatus?.days_remaining_in_trial ?? 0);
                setIsEmailVerified(subscriptionStatus?.email_verified ?? false);
                setIsLoadingSubscriptionState(false);
            });
        }); 
        }
    }, [user]);

    return {
        isSubscribed,
        setIsSubscribed,
        isTrialUser,
        setIsTrialUser,
        daysRemainingInTrial,
        setDaysRemainingInTrial,
        isLoadingSubscriptionState,
        setIsLoadingSubscriptionState,
        isEmailVerified
    }

}

export default useSubscriptionStatus;