import React from 'react';
import { useNavigate, Link, NavigateFunction } from 'react-router-dom';
import { User, LogoutOptions, RedirectLoginOptions, AppState } from "@auth0/auth0-react";
import { HStack, Heading, Image, Button, Text, List, ListItem, Box } from "@chakra-ui/react";
import { ChatIcon, AddIcon, ViewIcon } from "@chakra-ui/icons";
import { createStripePortalSession } from "../utils/apiUtils";

import { HamburgerMenu } from "./HamburgerMenu";
import { HamburgerDrawer } from './HamburgerDrawer';
import ChatHistoryDrawer from './ChatHistoryDrawer';
import { ChatMessage } from '../utils/apiUtils';
import { useAuth0 } from '@auth0/auth0-react';

type HeaderProps = {
    user: User | undefined;
    isAuthenticated: boolean;
    isTrialUser: boolean;
    daysRemainingInTrial: number;
    onLogin: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>;
    onSignup?: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>;
    onLogout: (options?: LogoutOptions | undefined) => Promise<void>;
    navigator: NavigateFunction;
    showChatHistory?: boolean;
    chatHistory?: ChatMessage[];
    onClickChatLink?: (sessionId: string) => void;
    onNewChatClick?: () => void;
}


export const Header = ({user, isAuthenticated, isTrialUser, daysRemainingInTrial, onLogin, onSignup, onLogout, navigator, showChatHistory, chatHistory, onClickChatLink, onNewChatClick}: HeaderProps) => {
    const {getAccessTokenSilently} = useAuth0();

    const createPortalSession = async () => {
        const token = await getAccessTokenSilently();
        return createStripePortalSession(token);
    }

    return (        
        <HStack as="nav" background={"transparent"} justify="space-between" align="center" w={"100%"} p={1} flexBasis={0}>
            {showChatHistory && <ChatHistoryDrawer 
                display={{ base: "block", md: "none" } as any}
                chatHistory={chatHistory ? chatHistory : []}
                onClickChatLink={onClickChatLink ? onClickChatLink : (sessionId: string) => {}}
                onNewChatClick={onNewChatClick ? onNewChatClick : () => {}}
            />}
            <Image src={"./images/The_Link_Black.png"} p={2} cursor="pointer" alt="SpecGPT Logo" height="60px" onClick={() => {
                if (isAuthenticated) {
                    navigator("/chat");
                } else {
                    navigator("/");
                }
            }}/>
            {isAuthenticated && user && <>
                <HStack display={{ base: "none", md: "block" }} spacing={5}>
                    <Button onClick={() => navigator('/chat')} mr={3} bg={"transparent"} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                        Chat
                    </Button>
                    <Button onClick={() => navigator('/getting-started')} mr={3} bg={"transparent"} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                        Add Documents
                    </Button>
                    <Button onClick={() => navigator('/uploaded-files')} mr={3} bg={"transparent"} _hover={{bg: 'lightBlue.50', cursor: 'pointer'}}>
                        View Documents
                    </Button>
                    <Button colorScheme='yellow' mr={3} onClick={() => onLogout({ logoutParams: { returnTo: window.location.origin } })}>
                        Logout
                    </Button>
                    <Button variant='outline' onClick={() => createPortalSession().then((url: string | null) => {
                        if (url) {
                            window.open(url, '_blank');
                        }
                    })}>{isTrialUser ? `${daysRemainingInTrial} day${daysRemainingInTrial > 1 ? "s" : ""} left in trial: Subscribe` : "Manage Subscription"}</Button>
                </HStack>
                <HamburgerDrawer
                    display={{ base: "block", md: "none" }}
                    user={user}
                    isTrialUser={isTrialUser}
                    daysRemainingInTrial={daysRemainingInTrial}
                    onLogout = {() => onLogout({ logoutParams: { returnTo: window.location.origin } })}
                    navigator={navigator}
                />
            </>}
            {!isAuthenticated && <HStack>
                <Button
                    variant='outline'
                    mr={3}
                    onClick={() => onLogin()}>
                    Log In
                </Button>
                <Button
                    colorScheme='yellow'
                    onClick={() => onSignup ? onSignup() : () => {}}>
                    Sign Up
                </Button>
            </HStack>}
        </HStack>
    )
}

export default Header;