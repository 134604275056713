import React from 'react';
import { Box, AspectRatio } from '@chakra-ui/react';

interface YoutubeEmbedProps {
  embedId: string;
  title: string;
}

const YoutubeEmbed = ({ embedId, title }: YoutubeEmbedProps) => (
    <AspectRatio width={{base: '90%', md: '50%'}}>
        <iframe
            title={title}
            src={`https://www.youtube.com/embed/${embedId}?autoplay=0&mute=1`}
            allowFullScreen
        />
    </AspectRatio>

);

export default YoutubeEmbed;