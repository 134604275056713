import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
  Link
} from "@chakra-ui/react";
import YoutubeEmbed from "./YoutubeEmbed";

type HeroVideoProps = {
    title: string,
    subtitle: string,
    ctaText: string,
    onClickCta: () => void,
    videoId: string,
    videoTitle: string,
}

export default function HeroVideo({
  title,
  subtitle,
  ctaText,
  onClickCta,
  videoId,
  videoTitle,
}: HeroVideoProps) {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column", md: "row" }}
      wrap="nowrap"
      minH="70vh"
      px={8}
      mb={16}
      mt={{ base: 8, md: 0 }}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
        >
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>
        <Button
            colorScheme="yellow"
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
            onClick={onClickCta}
        >
        {ctaText}
        </Button>
        <Text
          fontSize="xs"
          mt={2}
          textAlign="center"
          color="primary.800"
          opacity="0.6"
        >
          14 day free trial, no credit card required.
        </Text>
      </Stack>
      <Box w={{ base: "80%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }} mt={{base: 12, md: 0}}>
        <Image maxW={{base: '100%', md: '400px'}} margin={'auto'} src={"./images/SpecGPT_demo.gif"} alt="SpecGPT Demo"/>
        {/* <YoutubeEmbed embedId={videoId} title={videoTitle} /> */}
        {/* <Image src={image} boxSize="100%" rounded="1rem" shadow="2xl" /> */}
      </Box>
    </Flex>
  );
}
