import React, { useState, useEffect, useRef } from 'react';
import { MESSAGE_ROLE_TYPE } from '../utils/enums';
import { MessageBubble } from '../components/MessageBubble';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import useSubscriptionStatus from '../utils/useSubscriptionStatus';
import ChatHistoryDesktop from '../components/ChatHistoryDesktop';
import { fetchChatHistory, fetchChatSessionHistory, ChatMessage, ChatMessageDetail, fetchPromptAnswer } from '../utils/apiUtils';
import MessageSources from '../components/MessageSources';
import ChatInput from '../components/ChatInput';
import ExampleQuestionBubble from '../components/ExampleQuestionBubble';
import { Flex, Heading, VStack, Text, Stack, SimpleGrid, HStack, Container } from '@chakra-ui/react';



const ChatPage = () => {
    const navigator = useNavigate();
    const {loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, isLoading, logout} = useAuth0();
    const {isSubscribed, isTrialUser, daysRemainingInTrial} = useSubscriptionStatus();

    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<ChatMessageDetail[]>([]);
    const [k, setK] = useState(21);
    const [chatSessionId, setChatSessionId] = useState(uuidv4());
    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
    const [showLoadingMessage, setShowLoadingMessage] = useState(false);


    // const promptAreaRef = useRef(null);
    // useEffect(() => {
    //     if (promptAreaRef?.current?.style != null) {
    //         promptAreaRef.current.style.height = 'auto';
    //         promptAreaRef.current.style.height = `${promptAreaRef.current.scrollHeight}px`;
    //     }
    // }, [promptArea]);


    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently().then((token) => {
                fetchChatHistory(token).then((data) => {
                    setChatHistory(data);
                });
            });
        }
    }, [isAuthenticated, getAccessTokenSilently]);


    const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);    


    const submitMessage = () => {
        getChatResponse(userInput);
    }

    const submitMessageInline = (message: string) => { 
        console.log(message)
        if (!message) return;
        getChatResponse(message);
    }


    const getChatResponse = (userMessage: string) => {
        console.log('Submit message: ', userMessage);
        setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.USER, 'message': userMessage, 'session_id': chatSessionId, questionid: ''}]);
        setShowLoadingMessage(true);
        setUserInput('');
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
        getAccessTokenSilently().then((token) => {
            fetchPromptAnswer(userMessage, k, chatSessionId, token).then((newMessage) => {
                setShowLoadingMessage(false);
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            });
        });
    }

    const onNewChatClick = () => {
        navigator('/chat');
    }

    const onClickExampleMessageBubble = (text: string) => {
        setUserInput(text);
        submitMessageInline(text);
    }

    const onClickChatLink = (chatSessionId: string) => {
        getAccessTokenSilently().then((token) => {
            fetchChatSessionHistory(chatSessionId, token).then((messages) => {
                setMessages(messages);
                setChatSessionId(chatSessionId);
            });
        });
    }

    const QuestionBubbles = () => {
        return (
            <>
                <ExampleQuestionBubble
                    headerText='Ask me about'
                    bodyText="the weather parameters to consider when pouring concrete"
                    onClick={() => onClickExampleMessageBubble("What are the weather parameters to consider when pouring concrete?")}
                />
                <ExampleQuestionBubble
                    headerText='Ask me'
                    bodyText="what concrete tests are required?"
                    onClick={() => onClickExampleMessageBubble("What concrete tests are required?")}
                />             
                <ExampleQuestionBubble
                    headerText='Ask me about'
                    bodyText="the submittals required for cast in place concrete"
                    onClick={() => onClickExampleMessageBubble("What submittals are required for cast in place concrete?")}
                />
                <ExampleQuestionBubble
                    headerText='Create a list'
                    bodyText="of third party inspectors I'm required to have on this project"
                    onClick={() => onClickExampleMessageBubble("Create a list of third party inspectors I'm required to have on this project.")}
                />
            </>
        )
    }

    const ExampleQuestions = () => {
        return (
            <>
                <SimpleGrid columns={2} display={{ base: "none", md: "grid" }}>
                    <QuestionBubbles/>
                </SimpleGrid>
                <HStack overflowX={'scroll'} maxWidth={"100%"} p={0} display={{ base: "block", md: "none" }}>
                    <QuestionBubbles/>
                </HStack>
            </>
        )        
    }

    const BlankQuestionBlurb = () => {
        return (
            <Flex direction={'column'} align={'center'} justify={'center'} flexGrow={1}>
                <Container bg={'white'} p={4} borderRadius={4} boxShadow={'sm'}>
                    <Text>
                        SpecGPT is a powerful AI tool that has been engineered to answer
                        questions about your project specifications and related
                        documents that you upload. It can help quickly find what you're
                        looking for, summarize sections, and more.
                    </Text>
                    <Text>
                        However, like any tool, it has limits. If you ask questions that
                        require expertise, like "if anything is missing;" or questions that
                        are too broad, like "summarize the project," it will ask you to be
                        more specific.
                    </Text>
                </Container>
            </Flex>
        )
    }

    return (
        <Flex height={'100vh'}>
            <ChatHistoryDesktop 
                chatHistory={chatHistory}
                display={{ base: "none", md: "block" } as any}
                onClickChatLink={onClickChatLink}
                onNewChatClick={onNewChatClick}
            />
            
            <Flex direction="column" width={'100%'}>
                <Header
                    user={user}
                    isAuthenticated={isAuthenticated}
                    isTrialUser={isTrialUser}
                    daysRemainingInTrial={daysRemainingInTrial}
                    onLogout={logout}
                    onLogin={loginWithRedirect}
                    navigator={navigator}
                    showChatHistory={true}
                    chatHistory={chatHistory}
                    onClickChatLink={onClickChatLink}
                    onNewChatClick={onNewChatClick}
                />
                <VStack width={'100%'} justify={'end'} bg={'gray.50'} flexGrow={1} overflowY={'auto'}>
                    {messages.length === 0 && <BlankQuestionBlurb />}
                    <VStack width={'100%'} maxH={'100%'} overflowY={'auto'}>
                        {messages.length === 0 && <ExampleQuestions />}
                        { messages.map(
                            (message, index) => {
                                console.log(message)
                                return <MessageBubble key={index} 
                                    messageType={message.role as MESSAGE_ROLE_TYPE}
                                    onClick={() => {}}
                                    message={message.message} 
                                    questionid={message.questionid} 
                                    sources={message.sources}
                                />
                            }
                        )}
                        {showLoadingMessage && <MessageBubble 
                            messageType={MESSAGE_ROLE_TYPE.ASSISTANT}
                            message={""}
                            isLoading={true}
                        />}
                        <div ref={endOfMessagesRef}/>
                    </VStack>
                    <ChatInput 
                        submitMessage={submitMessage}
                        userInput={userInput}
                        onUserInputChange={setUserInput}
                        padding={6}
                    />
                </VStack>
            </Flex>
        </Flex>
    );
}   

export default ChatPage;