import React, { PropsWithChildren } from "react"
import { Box, Text } from "@chakra-ui/react"


interface FooterProps {
    color: string
}

const Footer = ({color, children}: PropsWithChildren<FooterProps>) => {
    return (
        <Box>
            <Text color={color}>{children}</Text>
        </Box>
    )
}

export default Footer
