import React, { useState, useEffect, useRef } from 'react';
import { config } from '../utils/config';
import Header from '../components/Header';
import { useNavigate } from "react-router-dom";
import { Container, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import HeroVideo from "../components/HeroVideo";
import Footer from "../components/Footer";
import useSubscriptionStatus from '../utils/useSubscriptionStatus';


import { useAuth0 } from "@auth0/auth0-react";
import YoutubeEmbed from '../components/YoutubeEmbed';


const LoginPage = () => {
    const navigate = useNavigate();
    const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, isLoading, logout } = useAuth0();
    const {isSubscribed, isTrialUser, daysRemainingInTrial} = useSubscriptionStatus();

    const onSignup = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/getting-started",
            },
            authorizationParams: {
                screen_hint: "signup",
            },
        });
    }


    return (        
        <Flex
            direction="column"
            align="center"
            maxW={{ xl: "1200px" }}
            m="0 auto"
        >
            <Header user={user} isAuthenticated={isAuthenticated} onLogin={loginWithRedirect} onSignup={onSignup} onLogout={logout} navigator={navigate} isTrialUser={isTrialUser} daysRemainingInTrial={daysRemainingInTrial} />
            <HeroVideo 
                title="A New Way To Work With Specs"
                subtitle="Transforming Construction Specs into Dynamic Conversations with Groundbreaking AI"
                ctaText="Get Started"
                onClickCta={onSignup}
                videoId='W15Bgs3TRzs'
                videoTitle='Product Tour'
            />
            <VStack width={'100%'} mb={'50px'}>
                <Heading>How It Works</Heading>
                <YoutubeEmbed embedId={"W15Bgs3TRzs"} title={"Product Tour"} />
            </VStack>
            <Footer color="gray.500">
                SpecGPT is a new way to access project information, starting with specifications. 
                The application is designed to allow for secure use by general and trade contractors.
                We have included the ability to upload your own project, then delete the files after your testing is done. 
                For a quick how to, check out the videos on this screen.&nbsp;
            </Footer>
        </Flex>
    );
}

export default LoginPage;