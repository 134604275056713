import React from "react";

import { Card, CardHeader, Text, Heading } from "@chakra-ui/react";
import { shortenString } from "../utils/textUtils";


const ExampleQuestionBubble = ({headerText, bodyText, onClick}: {headerText: string, bodyText: string, onClick: (text: string) => void}) => {
    return (
        <Card 
            onClick={() => onClick(`${headerText} ${bodyText}`)}
            maxW={{base: "95%", md: "40ch"}}
            h={"10ch"}
            p={4}
            bg={"white"}
            m={2}
            _hover={{bg: "blue.100"}}
        >
            <Heading size="sm">
                {headerText}
            </Heading>
            <Text>
                {shortenString(bodyText, 40)}
            </Text>
        </Card>
    )
}

export default ExampleQuestionBubble;